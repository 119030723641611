.footer {
  background-color: var(--secondary-color);
}

.footer-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.links a,p {
    color: #fff;
    text-decoration: none;
}
