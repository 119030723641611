.main {
    margin: 40px 0;
}

.main h3 {
    font-size: 3rem;
    margin: 20px 0;

    color: var(--secondary-color)
}

.main .grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
}

.main p {
    margin: 10px 0;
    color: var(--primary-color)
}

.main li {
    color: var(--primary-color)
}

.main p span {
    font-weight: 700;
}

.main .table-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 25px;
    margin-bottom: 25px;
}

.main .table {
    background-color: #c9c9c9;
}

.main .table-header {
    padding: 12px;
    margin: 0;

    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
}

.main .table-list {
    list-style-type: square;
    padding: 12px;
    padding-left: calc(1em + 12px);
    margin: 0
}


.main .table-list-item:not(:last-child) {
    margin-bottom: 4px;
    
}

.main .button-wrapper {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px ){
    .main .grid {
        grid-template-columns: minmax(0, 1fr);
    }
}