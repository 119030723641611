button {
    background-color: #fff;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);

    font-size: 16px;
    font-weight: 700;
    padding: 5px 15px;

    cursor: pointer;
}